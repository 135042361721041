import { styled } from '@obvio/app'
import Image from 'next/image'

import type { ReactElement } from 'react'

export const MarkerNode = styled.div<{ $active: boolean }>`
  cursor: pointer;
  position: relative;
  height: 0.8rem;
  width: 0.8rem;
  background: ${(_, { $active }) => ($active ? 'white' : '#8c8a7c')};
  border-radius: 99999px;
  z-index: ${(_, { $active }) => ($active ? 3 : 2)};
`

const Position = styled.div`
  position: absolute;
  left: calc(-13.375rem - 2rem);
  transform: translateY(-25%);
`

const shadowSize = '3rem'

const Shadow = styled.div`
  border-radius: 99999px;
  height: ${shadowSize};
  width: ${shadowSize};
  left: 0.4rem;
  top: 0.4rem;
  transform: translate(-50%, -50%);
  background: #bbbbba;
  z-index: 1;
  position: absolute;
`

const ForceImageWrap = styled.div`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
`

type MarkerProps = {
  lat: number
  lng: number
  active?: boolean
  children: ReactElement
  img?: string
  onClick?(): void
}

export function Marker({
  lat,
  lng,
  img,
  onClick,
  active = false,
  children,
}: MarkerProps): ReactElement {
  return (
    <>
      {img && (
        // prerender images
        <ForceImageWrap id="TEST">
          <Image
            src={img}
            layout="fill"
            objectFit="cover"
            alt={img}
            lazyBoundary="500px"
            sizes="300px"
          />
        </ForceImageWrap>
      )}
      <MarkerNode
        /** @ts-expect-error  */
        lat={lat}
        lng={lng}
        $active={active}
        onClick={onClick}
      >
        {active && <Position>{children}</Position>}
      </MarkerNode>
      {active && <Shadow />}
    </>
  )
}
