import { styled, Text, useTranslation } from '@obvio/app'
import { Stack } from '@obvio/ui'
import { motion } from 'framer-motion'
import Image from 'next/image'

import type { PlaceMarker } from '../JuraMap/data'
import type { ReactElement } from 'react'

const PopoverWrap = styled.div`
  width: 13.375rem;
`

const MotionWrap = motion(PopoverWrap)

const Block = styled(Text)`
  background: #8c8a7c;
  padding: ${(theme) => `${theme.spacing.extraSmall} ${theme.spacing.small}`};
  color: ${(theme) => theme.colors.primary};
  font-weight: 400;
  line-height: 135%;
`

const BlockDescription = styled(Block)`
  font-size: 0.75rem;
  line-height: 150%;
`

const ImageWrap = styled.div`
  width: 100%;
  height: 7.375rem;
  position: relative;
  margin-top: 0 !important;
`

type PlacePopoverProps = {
  place: PlaceMarker
}

export function PlacePopover({ place }: PlacePopoverProps): ReactElement {
  const { locale } = useTranslation()
  const image = `bg${Math.floor(Math.random() * 7) + 1}`

  return (
    <MotionWrap animate={{ y: [10, -5, 0], opacity: [0, 1] }}>
      <Stack kind="vertical" spacing="0.625rem">
        <Block>{place.content[locale].title}</Block>
        <BlockDescription>{place.content[locale].description}</BlockDescription>
        <ImageWrap>
          <Image
            src={place.img}
            layout="fill"
            objectFit="cover"
            alt={image}
            lazyBoundary="500px"
            sizes="300px"
          />
        </ImageWrap>
      </Stack>
    </MotionWrap>
  )
}
