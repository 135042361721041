import type { Dict } from '@obvio/utils'

export type PlaceMarker = {
  id: string
  content: Dict<{
    title: string
    description: string
  }>
  position: {
    lat: number
    lng: number
  }
  img: string
}

export const markers: PlaceMarker[] = [
  {
    id: '1',
    content: {
      pl: {
        title: 'Zamek Ogrodzieniec',
        description:
          'Znajdujący się po sąsiedzku Zamek Ogrodzieniec znany jest już na całym świecie. Rok w rok tysiące turystów przyjeżdża do Podzamcza, żeby go zobaczyć...',
      },
      en: {
        title: 'Ogrodzieniec Castle',
        description:
          'Located next to each other, Ogrodzieniec Castle is already known worldwide. Year after year, thousands of tourists come to Podzamcze to see it...',
      },
    },
    position: {
      lat: 50.453_297_120_202_215,
      lng: 19.552_029_825_403_3,
    },
    img: '/static/img/map/okolice-jury-Zamek-Ogrodzieniec.jpg',
  },
  {
    id: '2',
    content: {
      pl: {
        title: 'Góra Birów',
        description:
          'Malowniczo położony zrekonstruowany Gród Królewski na Górze Birów jest idealnym miejscem dla turystów ciekawych historycznych walorów Jury.',
      },
      en: {
        title: 'Birów Mountain',
        description:
          'The beautifully located reconstructed Royal Stronghold on Birów Mountain is the perfect place for tourists interested in the historical values of the Jura region.',
      },
    },
    position: {
      lat: 50.464_649_928_393_285,
      lng: 19.551_174_371_431_436,
    },
    img: '/static/img/map/Góra-Birów.jpg',
  },
  {
    id: '3',
    content: {
      pl: {
        title: 'Pustynia Błędowska',
        description:
          'To jedna z większych ciekawostek przyrodniczych i krajobrazowych w Polsce, pustynia Błędowska rozciąga się na obszarze aż 33 km²',
      },
      en: {
        title: 'Błędów Desert',
        description:
          'One of the most fascinating natural and landscape attractions in Poland, the Błędów Desert extends over an area of 33 km².',
      },
    },
    position: {
      lat: 50.340_289_863_867_78,
      lng: 19.519_915_081_316_093,
    },
    img: '/static/img/map/Pustynia-Błędowska.jpg',
  },
  {
    id: '4',
    content: {
      pl: {
        title: 'Zbrojownia Jurajska w Smoleniu',
        description:
          'U stóp zamku Pilcza w tzw. Zbrojowni Jurajskiej dla zainteresowanych historią czekają eksponaty z różnych epok historycznych.',
      },
      en: {
        title: 'Jurassic Armory in Smolenie',
        description:
          'At the foot of Pilcza Castle, in the so-called Jurassic Armory, exhibits from various historical periods await history enthusiasts.',
      },
    },
    position: {
      lat: 50.439_288_192_366_52,
      lng: 19.677_073_492_109_41,
    },
    img: '/static/img/map/okolice-jury-Zbrojownia-Jurajska-w-Smoleniu.jpg',
  },
  {
    id: '5',
    content: {
      pl: {
        title: 'Zamek Pilcza w Smoleniu',
        description:
          'Ruiny z ciekawą historią i wyjątkowym klimatem. Dzięki położeniu na terenie rezerwatu przyrody jest idealnym miejscem na spacer.',
      },
      en: {
        title: 'Pilcza Castle in Smolenie',
        description:
          'Ruins with an interesting history and unique atmosphere. Thanks to its location in a nature reserve, it is an ideal place for a walk.',
      },
    },
    position: {
      lat: 50.438_518_628_550_56,
      lng: 19.674_873_469_368_546,
    },
    img: '/static/img/map/okolice-jury-Zamek-Pilcza-w-Smoleniu.jpg',
  },
  {
    id: '6',
    content: {
      pl: {
        title: 'Zegarowe Skały w Dolinie Wodącej',
        description:
          'To niesamowite mury skalne, które najlepiej podziwiać podczas pieszych wędrówek licznymi  malowniczymi  szlakami Doliny Wodącej.',
      },
      en: {
        title: 'Clock Rocks in Wodąca Valley',
        description:
          'These amazing rock formations are best admired during hiking trips along the numerous scenic trails of Wodąca Valley.',
      },
    },
    position: {
      lat: 50.428_248_587_587_01,
      lng: 19.674_765_200_053_468,
    },
    img: '/static/img/map/Zegarowe-Skały-w-Dolinie-Wodącej.jpg',
  },
  {
    id: '7',
    content: {
      pl: {
        title: 'Skała Furtki',
        description:
          'Wysoka na 10 metrów wapienna skała w którą wkomponowany został mur  Zamku Ogrodzieniec. Na szczyt skały prowadzi 6 dróg wspinaczkowych.',
      },
      en: {
        title: 'Gate Rock',
        description:
          'A 10-meter high limestone rock integrated with the wall of Ogrodzieniec Castle. There are 6 climbing routes leading to the top of the rock.',
      },
    },
    position: {
      lat: 50.452_706_357_027_98,
      lng: 19.552_492_700_054_263,
    },
    img: '/static/img/map/Skała-Furtki.jpg',
  },
  {
    id: '8',
    content: {
      pl: {
        title: 'Skała Niedźwiedzia',
        description:
          'Skała ciesząca się dużą popularnością wśród wspinaczy skalnych. Znajduje się na niej aż 12 dróg wspinaczkowych o zróżnicowanej trudności.',
      },
      en: {
        title: 'Bear Rock',
        description:
          'A highly popular rock among rock climbers. It features 12 climbing routes of varying difficulty.',
      },
    },
    position: {
      lat: 50.452_512_935_690_15,
      lng: 19.553_165_602_116_408,
    },
    img: '/static/img/map/Skała-Niedźwiedź.jpg',
  },
  {
    id: '9',
    content: {
      pl: {
        title: 'Skała Lalka',
        description:
          'Zbudowana z twardych wapieni, ma postać słupa o wysokości 12 m.  Na szczyt prowadzi jedna droga wspinaczkowa z 4 ringami i stanowiskiem zjazdowym.',
      },
      en: {
        title: 'Doll Rock',
        description:
          'Built from hard limestone, it takes the form of a 12-meter pillar. There is one climbing route leading to the top with 4 anchor points and a rappel station.',
      },
    },
    position: {
      lat: 50.452_528_741_877_806,
      lng: 19.553_342_569_581_584,
    },
    img: '/static/img/map/Skała-Lalka.jpg',
  },
  {
    id: '10',
    content: {
      pl: {
        title: 'Skalne Miasto Podzamcza',
        description:
          'Wokół Zamku Ogrodzieniec wznoszą się uwielbiane przez amatorów wspinaczki liczne ostańce wapienne potocznie zwane Skalnym Miastem Podzamcza.',
      },
      en: {
        title: 'Rock City of Podzamcze',
        description:
          'Numerous limestone rock formations, popularly known as the Rock City of Podzamcze, surround the Ogrodzieniec Castle and are loved by climbing enthusiasts.',
      },
    },
    position: {
      lat: 50.451_589_175_709_27,
      lng: 19.554_150_271_431_077,
    },
    img: '/static/img/map/Skalne-Miasto-Podzamcza.jpg',
  },
  {
    id: '11',
    content: {
      pl: {
        title: 'Pałac w Pilicy',
        description:
          'Pałac, pomimo że nie jest udostępniony dla turystów oferuje ciekawe miejsce na spacer w parku uznanym za pomnik przyrody.',
      },
      en: {
        title: 'Pilica Palace',
        description:
          'Although the palace is not accessible to tourists, it offers an interesting place for a walk in the park, recognized as a natural monument.',
      },
    },
    position: {
      lat: 50.467_852_823_012_92,
      lng: 19.649_584_291_564_423,
    },
    img: '/static/img/map/Jura-Krakowsko-Częstochowska-Pałac-w-Pilicy.jpg',
  },
  {
    id: '12',
    content: {
      pl: {
        title: 'Pałac w Dupnicy',
        description:
          'Schowana w lesie nietypowa skała z 10-cio metrowym tunelem skalnym, który “przebija” skałę na wylot. ',
      },
      en: {
        title: 'Dupnica Palace',
        description:
          'Hidden in the forest, there is an unusual rock with a 10-meter long rock tunnel that pierces through the rock.',
      },
    },
    position: {
      lat: 50.431_721_121_433_2,
      lng: 19.612_732_352_176_057,
    },
    img: '/static/img/map/Skała-z-Tunelem-w-Dupnicy.jpg',
  },
  {
    id: '13',
    content: {
      pl: {
        title: 'Strażnica w Ryczowie',
        description:
          'Powstała prawdopodobnie w końcu XIV w. budowla obronna z zachowanymi do dziś  fragmentami murów i wału o wysokości ok. 1,5 m.',
      },
      en: {
        title: 'Watchtower in Ryczów',
        description:
          'It was probably built in the late 14th century as a defensive structure with preserved fragments of walls and a rampart about 1.5 meters high.',
      },
    },
    position: {
      lat: 50.427_649_114_890_6,
      lng: 19.601_791_111_696_798,
    },
    img: '/static/img/map/Strażnica-w-Ryczowie.jpg',
  },
  {
    id: '14',
    content: {
      pl: {
        title: 'Góra Chełm w Hutkach Kankach',
        description:
          'Rezerwat leśny ze skalistym wzgórzem (443m n.p.m.) pokryty starodrzewiem z okazami nawet 200-stu letnich buków na szczycie.',
      },
      en: {
        title: 'Mount Chełm in Hutki Kanki',
        description:
          'A forest reserve with a rocky hill (443 meters above sea level) covered with old-growth trees, including beech trees that are even 200 years old at the top.',
      },
    },
    position: {
      lat: 50.408_627_724_373_915,
      lng: 19.501_066_927_038_68,
    },
    img: '/static/img/map/Góra-Chełm-w-Hutkach-Kankach.jpg',
  },
  {
    id: '15',
    content: {
      pl: {
        title: 'Okiennik Wielki',
        description:
          'Te przekraczające 30 metrów ściany skalne są jednym z najpiękniejszych widoków  jurajskiego krajobrazu, który warto zobaczyć.',
      },
      en: {
        title: 'Okiennik Wielki',
        description:
          'These cliffs, reaching over 30 meters in height, are one of the most beautiful views of the Jura landscape that is worth seeing.',
      },
    },
    position: {
      lat: 50.524_185_919_712_19,
      lng: 19.523_643_141_839_507,
    },
    img: '/static/img/map/Okiennik-Wielki.jpg',
  },
  {
    id: '16',
    content: {
      pl: {
        title: 'Punkt widokowy Czubatka',
        description:
          'Czubatka (382 n.p.m.) to wzgórze a tym samym punkt widokowy z którego rozpościera się piękny widok na Pustynię Błędowską. ',
      },
      en: {
        title: 'Czubatka Viewpoint',
        description:
          'Czubatka (382 m above sea level) is a hill and a viewpoint that offers a beautiful view of the Błędowska Desert.',
      },
    },
    position: {
      lat: 50.336_855_525_599_11,
      lng: 19.549_410_766_733_33,
    },
    img: '/static/img/map/Punkt-widokowy-Czubatka.jpg',
  },
  {
    id: '17',
    content: {
      pl: {
        title: 'Zamek Bąkowiec w Morsku',
        description:
          'Ruiny zamku rycerskiego „Bąkowiec” pochodzącego z XIV-XV w.',
      },
      en: {
        title: 'Bąkowiec Castle in Morsko',
        description:
          "The ruins of the knight's castle 'Bąkowiec' dating back to the 14th-15th centuries.",
      },
    },
    position: {
      lat: 50.550_910_382_011_49,
      lng: 19.520_765_155_879_527,
    },
    img: '/static/img/map/Zamek-Bąkowiec-w-Morsku.jpg',
  },
  {
    id: '18',
    content: {
      pl: {
        title: 'Wzgórze Kromołowiec',
        description:
          'Ostaniec wapienny (431 m n.p.m) z którego rozpościera się piękny widok na cztery strony świata w tym m.in. na Pustynię Błędowską. ',
      },
      en: {
        title: 'Kromołowiec Hill',
        description:
          'Kromołowiec is a limestone outlier (431 m above sea level) that offers a beautiful view in all four cardinal directions, including the Błędowska Desert.',
      },
    },
    position: {
      lat: 50.399_194_594_679_344,
      lng: 19.436_231_798_202_737,
    },
    img: '/static/img/map/Wzgórze-Kromołowiec.jpg',
  },
  {
    id: '19',
    content: {
      pl: {
        title: 'Punkt widokowy Dąbrówka',
        description:
          'Dąbrówka (355 m n.p.m.) to wzgórze a tym samym punkt widokowy z którego rozpościera się piękny widok na Pustynię Błędowską. ',
      },
      en: {
        title: 'Dąbrówka Viewpoint',
        description:
          'Dąbrówka (355 meters above sea level) is a hill and viewpoint that offers a beautiful view of the Błędów Desert.',
      },
    },
    position: {
      lat: 50.360_450_436_913_936,
      lng: 19.519_715_398_201_356,
    },
    img: '/static/img/map/Punkt-widokowy-Dąbrówka.jpg',
  },
  {
    id: '20',
    content: {
      pl: {
        title: 'Ruiny Zamku w Bydlinie',
        description:
          'Ruiny zamku wznoszące się na wysokim, skalistym wzgórzu z fragmentami murów dawnej strażnicy z XIV wieku.',
      },
      en: {
        title: 'Ruins of Bydlin Castle',
        description:
          'The ruins of a castle situated on a high, rocky hill with remnants of the walls of an ancient watchtower from the 14th century.',
      },
    },
    position: {
      lat: 50.388_920_189_073_01,
      lng: 19.642_857_742_380_63,
    },
    img: '/static/img/map/Ruiny-Zamku-w-Bydlinie.jpg',
  },
]
