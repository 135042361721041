import { styled, useInView } from '@obvio/app'
import { Section } from '@obvio/ui'
import GoogleMapReact from 'google-maps-react-markers'
import { useEffect, useState } from 'react'

import { MAP_STYLE } from '../JuraMapStyles'
import { Marker } from '../Map/Marker'
import { PlacePopover } from '../Map/PlacePopover'
import { markers } from './data'

import type { ReactElement } from 'react'

const MapWrap = styled(Section)`
  padding: 0 !important;
  margin-top: ${(theme) => theme.spacing.extraLarge};
  height: ${(theme) => `calc(52rem + ${theme.spacing.extraLarge} * 2)`};
  width: 100%;
  position: relative;
  max-width: 100vw !important;
`

export function JuraMap(): ReactElement {
  const [displayed, setDisplayed] = useState(false)
  const { ref, inView } = useInView()
  const [activeMarker, setActiveMarker] = useState<string | null>('1')

  const toggleMarker = (id: string | null) =>
    setActiveMarker((currentMarker) => (currentMarker === id ? null : id))

  useEffect(() => {
    if (inView) {
      setDisplayed(true)
    }
  }, [inView])

  return (
    <MapWrap ref={ref}>
      {displayed && (
        <GoogleMapReact
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API}
          defaultCenter={{ lat: 50.433, lng: 19.533 }}
          defaultZoom={13}
          options={{
            styles: MAP_STYLE,
            disableDefaultUI: true,
            restriction: {
              latLngBounds: {
                north: 50.733,
                south: 50.233,
                west: 19.233,
                east: 19.833,
              },
            },
          }}
        >
          {markers.map((place) => (
            <Marker
              key={place.id}
              lat={place.position.lat}
              lng={place.position.lng}
              active={activeMarker === place.id}
              img={place.img}
              onClick={() => toggleMarker(place.id)}
            >
              <PlacePopover place={place} />
            </Marker>
          ))}
        </GoogleMapReact>
      )}
    </MapWrap>
  )
}
